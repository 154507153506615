<template>
  <div class="custom-select" :tabindex="tabindex" @blur="open = false">
    <div class="selected" :class="[{ open: open }]" @click="open = !open">
      <country-flag :country="selected.code" size="small" />
      {{ selected.dial_code }}
    </div>
    <div class="items" :class="{ selectHide: !open }">
      <div
        v-for="(option, i) of options"
        :key="i"
        @click="
          selected = option;
          open = false;
          $emit('input', option.dial_code);
        "
      >
        <country-flag :country="option.code" size="small" />
        {{ option.name }}
        <span class="secondary">({{ option.dial_code }})</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      required: true,
    },
    default: {
      type: String,
      required: false,
      default: null,
    },
    isError: {
      required: false,
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
    submitted: {
      required: false,
    },
  },
  data() {
    return {
      selected: { code: "US", dial_code: "+1" },
      open: false,
      multiSelected: [],
    };
  },
  methods: {
    removeItem(val) {
      this.multiSelected = this.multiSelected.filter((item) => item !== val);
      this.$emit("multi", this.multiSelected);
    },
    isUnique(comparer) {
      for (var i = 0; i < this.multiSelected.length; i++) {
        if (comparer === this.multiSelected[i]) return false;
      }
      return true;
    },
    sendData(option) {
      if (this.multiselect && this.isUnique(option)) {
        this.multiSelected.push(option);
        this.$emit("multi", this.multiSelected);
      } else if (this.withObj && !this.multiselect) {
        this.selected = option.name;
      } else {
        this.selected = option;
        this.$emit("input", option);
        this.open = false;
      }
    },
  },
  watch: {
    tt(val) {
      if (val === "odd" || val === "even") {
        this.selected = "Выбрать день";
      }
    },
    submitted() {
      if (this.submitted === true) {
        this.selected = this.default;
        this.$emit("changee");
      }
    },
  },
};
</script>

<style scoped>
.secondary {
  color: #767676;
}
.custom-select {
  /* height: 100%; */
  /* width: 170px; */
  /* line-height: 20px; */
  font-size: 13px;
  position: relative;
}
.custom-select .selected {
  display: flex;
  align-items: center;
  gap: 4px;
  position: relative;
  background: rgb(225, 224, 224);
  width: max-content;
  /* border-radius: 6px; */
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  height: 93%;
  /* border: 1px solid #ced4da; */
  color: #212529;
  padding: 6px 1.4rem 6px 2px;
  cursor: pointer;
  user-select: none;
}
/* .custom-select .selected.open {
  border-radius: 0px 6px 0px 0px;
} */

.custom-select .selected.activeBg,
.custom-select .selected:hover {
  background: #016bd4;
  color: #fff;
}
.custom-select .selected:after {
  position: absolute;
  content: "";
  transition: all 0.15s ease;
  top: 15px;
  right: 8px;
  width: 0;
  height: 0;
  border: 4px solid transparent;
  border-color: #212529 transparent transparent transparent;
}
/* .custom-select:hover .selected:after, */
.custom-select .selected.activeBg:after {
  position: absolute;
  content: "";
  transition: all 0.2s ease;
  top: 8px;
  right: 2em;
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-color: #fff transparent transparent transparent;
}
/* .custom-select .selected.open:after {
  top: 10px;
  transform: rotate(180deg);
} */
.custom-select .items {
  max-height: 13rem;
  width: 14rem;
  overflow-y: scroll;
  color: #212529;
  border-radius: 0px 0px 6px 6px;
  position: absolute;
  background-color: #fff;
  border: 1px solid #ced4da;
  left: 0;
  right: 0;
  z-index: 100;
}
/* ::-webkit-scrollbar {
  display: none;
} */
.custom-select .items div {
  position: relative;
  padding: 6px 12px;
  color: rgb(68, 68, 68);
  border-top: 0.1px solid #ced4da;
  padding-left: 1em;
  transition: all 0.2s ease;
  cursor: pointer;
  user-select: none;
}

.custom-select .items div:hover {
  background-color: rgba(19, 23, 40, 0.1);
}
.selectHide {
  display: none;
}
.custom-select .selected:hover {
  background: rgb(225, 224, 224);
  color: #000;
}
</style>
